.shimmer {
    color: #ffffff; /* Base text color */
    background-image: linear-gradient(
      to right,
      #ffffff 20%,
      #0B1728 40%,
      #0B1728 40%,
      #0B1728 50%,
      #0B1728 40%,
      #ffffff 60%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200% 100%;
    background-position: -100%;
    animation: shimmer 3s infinite linear;
    font-size: 25px;
    font-weight: 550;
    text-shadow: 
    2px 2px 0 #000000,
    3px 3px 0 #a2a2a2;
  }
  
  @keyframes shimmer {
    100% {
      background-position: 100%;
    }
  }
  